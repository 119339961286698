const jooApiBaseUrl = 'https://app.dev.joo.kz';
const jooApiBaseUrlV1 = `${jooApiBaseUrl}/api/v1`;
const jooApiBaseUrlV2 = `${jooApiBaseUrl}/api/v2`;

/**
 * @deprecated jooApiUrl no longer supported, use jooApiBaseUrl instead
 */
const jooApiUrl = 'https://app.dev.joo.kz/api/v1';

/**
 * @deprecated jooApiCoursesUrl no longer supported, use jooApiBaseUrl instead
 */
const jooApiCoursesUrl = `${jooApiUrl}/matrix`;

/**
 * @deprecated jooApiSupportUrl no longer supported, use jooApiBaseUrl instead
 */
const jooApiSupportUrl = `${jooApiUrl}/support/staff`;

export const environment = {
  production: false,
  jooApiBaseUrl,
  jooApiBaseUrlV1,
  jooApiBaseUrlV2,
  jooApiUrl,
  jooApiCoursesUrl,
  jooApiSupportUrl,
};
